import 'assets/styles/app.scss';
import 'magnific-popup/dist/jquery.magnific-popup.min.js';

const loadedComponents = [];

document.addEventListener( 'DOMContentLoaded', async () => {
	/**
	 * Bootstrap the components
	 */
	for ( const dataComponent of document.querySelectorAll( '[data-component]' ) ) {
		let componentName = dataComponent.dataset.component;

		if ( loadedComponents.indexOf( componentName ) === - 1 ) {
			loadedComponents.push( componentName );

			try {
				let component = await import(
					/* WebpackMode: "lazy" */
					/* webpackPrefetch: true */
					/* webpackPreload: true */
					`components/${componentName}/${componentName}.js`
					);

				component.default();
			} catch ( error ) {
				console.error( 'Loading error: ', error );
			}
		}
	}

	$( '.js-photo-popup' ).each( function() {
		$( this ).magnificPopup( {
			delegate: 'a',
			type: 'image',
			tLoading: 'Loading image #%curr%...',
			mainClass: 'mfp-img-mobile',
			gallery: {
				enabled: true,
				navigateByImgClick: true,
				preload: [
					0,
					1,
				], // Will preload 0 - before current, and 1 after the current image
			},
		} );
	} );

	$( '.js-photo-nav li a' ).click( function( e ) {
		e.preventDefault();
		let $href = $( this ).attr( 'href' );
		if ( $( this ).parent().hasClass( 'is-active' ) ) {
			$( '#albumAll' ).fadeIn( 'fast' ).siblings().hide();
			$( this ).parent( 'li' ).removeClass( 'is-active' )
		} else {
			$( $href ).fadeIn( 'fast' ).siblings().hide();
			$( this ).parent( 'li' ).toggleClass( 'is-active' ).siblings( 'li' ).removeClass( 'is-active' );
		}
	} )

} );
